import styled from 'styled-components';

interface Props {
  title: string;
  required?: boolean;
}

const TitleContainer = styled.div`
  padding: 1.5rem 0.875rem;
  min-width: 11rem;
  background-color: #e3e8f0;
  font-weight: 600;
  font-size: 0.875rem;
  color: #555555;

  .required {
    padding-left: 4px;
    color: #ef4b56;
  }
`;

const CardTitle = ({ title, required }: Props) => {
  return (
    <TitleContainer>
      {title}
      {required && <span className="required">*</span>}
    </TitleContainer>
  );
};

export default CardTitle;
