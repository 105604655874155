import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ConfigImageState, ConfigType } from '../../types/config';
import FormField from './common/FormField';
import ImageUploader from './common/ImageUploader';

interface Props {
  configInfo: ConfigType;
  images: ConfigImageState;
  handleCancel: () => void;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleUpdate: () => void;
  handleChangeImage: (e: ChangeEvent<HTMLInputElement>, key: keyof ConfigImageState) => void;
  handleDeleteImage: (key: keyof ConfigImageState) => void;
}

const FormCardDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: #ffffff;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 20%), 0 2px 4px -2px rgb(0 0 0 / 30%);

  .title-block {
    width: 12.75rem;
    height: 1.5rem;
    background-color: #e3e8f0;
  }

  .gap-24 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
`;

const FormFootWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;

  button {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
  }

  .btn-item-cancel {
    background-color: #dc343f;
    &:hover {
      background-color: #ef4b56;
    }
  }

  .btn-item-add {
    background-color: #044dac;
    &:hover {
      background-color: #4c9ffe;
    }
  }
`;

const HotelConfig = ({ configInfo, images, handleCancel, handleChange, handleUpdate, handleChangeImage, handleDeleteImage }: Props) => {
  const {
    sa_api_key,
    id_prefix,
    api_key,
    checkinable_not_paid,
    checkinable_not_assigned,
    link_pms_name,
    link_code,
    is_issued_key,
    is_ignore_paid,
    reservation_no_prefix,
    reservation_no_postfix,
    timeout,
    checkin_enable_time,
    customer_service_number,
    latitude,
    longitude,
    radius,
    hotel_image,
    rooms_image,
  } = configInfo;
  const booleanOptions = [
    { label: 'O', value: 'TRUE' },
    { label: 'X', value: 'FALSE' },
  ];

  return (
    <>
      <FormCardDetail>
        <div className="gap-24">
          <div>
            <FormField title="시설 특정 코드" name="id_prefix" value={id_prefix} onChange={handleChange} required />
            <FormField title="API Key" name="api_key" value={api_key} onChange={handleChange} required />
            <FormField title="결제 유무" name="checkinable_not_paid" value={checkinable_not_paid} onChange={handleChange} options={booleanOptions} required />
            <FormField
              title="객실 선택 유무"
              name="checkinable_not_assigned"
              value={checkinable_not_assigned}
              onChange={handleChange}
              options={booleanOptions}
              required
            />
            <FormField title="Issued Key" name="is_issued_key" value={is_issued_key} options={booleanOptions} onChange={handleChange} required />
            <FormField title="Ignore Paid" name="is_ignore_paid" value={is_ignore_paid} options={booleanOptions} onChange={handleChange} required />
            <FormField title="PMS Link Code" name="link_code" value={link_code} onChange={handleChange} required />
            <FormField title="PMS Name" name="link_pms_name" value={link_pms_name} onChange={handleChange} required />
            <FormField title="Smart Checkin URL Prefix" name="reservation_no_prefix" value={reservation_no_prefix} onChange={handleChange} />
            <FormField title="Smart Checkin URL Postfix" name="reservation_no_postfix" value={reservation_no_postfix} onChange={handleChange} />
            <FormField title="Time Out" name="timeout" value={timeout} onChange={handleChange} type="number" />
            <FormField title="SA API Key" name="sa_api_key" value={sa_api_key} onChange={handleChange} disabled />
          </div>
          <div>
            <FormField title="체크인 활성화 시간" name="checkin_enable_time" value={checkin_enable_time} onChange={handleChange} required />
            <FormField title="위도" name="latitude" value={latitude} onChange={handleChange} type="number" required />
            <FormField title="경도" name="longitude" value={longitude} onChange={handleChange} type="number" required />
            <FormField title="위치 반경" name="radius" value={radius} onChange={handleChange} type="number" required />
            <FormField title="호텔 전화번호" name="customer_service_number" value={customer_service_number} onChange={handleChange} />
          </div>
        </div>

        <div>
          <ImageUploader
            title="호텔 이미지"
            image={images.hotel}
            fallbackImage={hotel_image}
            onChange={(e) => handleChangeImage(e, 'hotel')}
            onDelete={() => handleDeleteImage('hotel')}
          />
          <div className="title-block" />
          {checkinable_not_assigned === 'TRUE' && (
            <ImageUploader
              title="객실 선택 이미지"
              image={images.rooms}
              fallbackImage={rooms_image}
              onChange={(e) => handleChangeImage(e, 'rooms')}
              onDelete={() => handleDeleteImage('rooms')}
            />
          )}
        </div>
      </FormCardDetail>

      <FormFootWrap>
        <button className="btn-item-cancel" onClick={handleCancel}>
          <FontAwesomeIcon icon={faTimesCircle} />
          취소
        </button>
        <button className="btn-item-add" onClick={handleUpdate}>
          <FontAwesomeIcon icon={faSave} />
          저장
        </button>
      </FormFootWrap>
    </>
  );
};

export default HotelConfig;
