import CardTitle from './CardTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  image: File | null;
  fallbackImage: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
}

const Box = styled.div`
  display: flex;
  column-gap: 1.5rem;

  .image-box {
    position: relative;
    padding: 0.875rem;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .image {
    width: 340px;
    height: 100%;
    border-radius: 0.25rem;
    object-fit: cover;
  }
  .delete-button {
    position: absolute;
    top: 4px;
    right: 4px;
    border: none;
    padding: 8px 10px;
    border-radius: 50%;
    background-color: #e7e7e7;
    cursor: pointer;
  }
`;

const ImageUploader = ({ title, image, fallbackImage, onChange, onDelete }: Props) => (
  <Box>
    <CardTitle title={title} />
    <input type="file" accept="image/*" onChange={onChange} hidden={!!image || !!fallbackImage} />
    {(image || fallbackImage) && (
      <div className="image-box">
        <button className="delete-button" type="button" onClick={onDelete}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <img className="image" src={image ? URL.createObjectURL(image) : fallbackImage} alt={title} />
      </div>
    )}
  </Box>
);

export default ImageUploader;
