import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stores';
import HotelConfig from '../../components/Hotel/HotelConfig';
import { selectConfigAction, changeField, changeResult, updateConfigAction } from '../../stores/config';
import { ConfigImageState } from '../../types/config';
import { getHotelId } from '../../api/lib/common';
import { ResponseFailModal, ResponseSuccessModal } from '../../components/Modal/Response';

interface Props {
  isOpen: boolean;
}

const HotelConfigContainer = ({ isOpen }: Props) => {
  const dispatch = useDispatch();
  const { configInfo, configUpdateSuccess, configUpdateError, configSelectError } = useSelector(
    ({ config: { config, configUpdateSuccess, configUpdateError, configSelectError } }: RootState) => ({
      configInfo: config,
      configUpdateSuccess,
      configUpdateError,
      configSelectError,
    })
  );
  const [images, setImages] = useState<ConfigImageState>({ hotel: null, rooms: null });
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isFailModal, setIsFailModal] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>, key: keyof ConfigImageState) => {
    const file = event.target.files?.[0];
    if (file) setImages((prevImages) => ({ ...prevImages, [key]: file }));
  };

  const handleDeleteImage = (key: keyof ConfigImageState) => {
    setImages((prevImages) => ({ ...prevImages, [key]: null }));
    dispatch(changeField({ key: `${key}_image`, value: null }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(changeField({ key: name, value }));
  };

  const handleUpdate = () => {
    const formData = new FormData();

    formData.append('hotel_id', getHotelId());
    formData.append('id_prefix', configInfo.id_prefix);
    formData.append('api_key', configInfo.api_key);
    formData.append('checkinable_not_paid', configInfo.checkinable_not_paid);
    formData.append('checkinable_not_assigned', configInfo.checkinable_not_assigned);
    formData.append('link_pms_name', configInfo.link_pms_name);
    formData.append('link_code', configInfo.link_code);
    formData.append('is_issued_key', configInfo.is_issued_key);
    formData.append('is_ignore_paid', configInfo.is_ignore_paid);
    formData.append('reservation_no_prefix', configInfo.reservation_no_prefix);
    formData.append('reservation_no_postfix', configInfo.reservation_no_postfix);
    formData.append('timeout', String(configInfo.timeout));
    formData.append('latitude', String(configInfo.latitude));
    formData.append('longitude', String(configInfo.longitude));
    formData.append('radius', String(configInfo.radius));
    formData.append('checkin_enable_time', configInfo.checkin_enable_time);
    formData.append('customer_service_number', configInfo.customer_service_number);

    if (images.hotel) formData.append('hotel_image', images.hotel);
    if (images.rooms) formData.append('rooms_image', images.rooms);

    dispatch(updateConfigAction(formData));
  };

  const handleSelectConfig = useCallback(() => {
    dispatch(selectConfigAction());
    setImages({ hotel: null, rooms: null });
  }, [dispatch]);

  useEffect(() => {
    if (configUpdateError) {
      const errorMessage = configUpdateError.response ? configUpdateError.response.data.message : configUpdateError.message;
      setUpdateMessage(errorMessage);
      setIsFailModal(true);
      dispatch(changeResult({ key: 'configUpdateError', value: null }));
      return;
    }
    if (configUpdateSuccess) {
      setUpdateMessage('업데이트가 완료되었습니다.');
      setIsSuccessModal(true);
      dispatch(changeResult({ key: 'configUpdateSuccess', value: false }));
    }
  }, [configUpdateSuccess, configUpdateError, dispatch]);

  useEffect(() => {
    if (isOpen) {
      handleSelectConfig();
    }
  }, [handleSelectConfig, isOpen]);

  // TODO: 스마트 체크인 SA-BE 모든 pms 연동 완료시 수정 필요!
  if (configSelectError) return <div>스마트 체크인 Config를 조회 할 수 없습니다.</div>;

  return (
    <>
      <HotelConfig
        configInfo={configInfo}
        images={images}
        handleCancel={handleSelectConfig}
        handleChange={handleChange}
        handleUpdate={handleUpdate}
        handleChangeImage={handleChangeImage}
        handleDeleteImage={handleDeleteImage}
      />
      <ResponseSuccessModal isOpen={isSuccessModal} toggle={() => setIsSuccessModal(!isSuccessModal)} message={updateMessage} />
      <ResponseFailModal isOpen={isFailModal} toggle={() => setIsFailModal(!isFailModal)} message={updateMessage} />
    </>
  );
};

export default HotelConfigContainer;
