import { ChangeEvent } from 'react';
import CardTitle from './CardTitle';
import styled from 'styled-components';

interface Props {
  title: string;
  name: string;
  value: string | number;
  type?: string;
  required?: boolean;
  options?: { label: string; value: string | number }[];
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  disabled?: boolean;
}

const Box = styled.div`
  display: flex;
  column-gap: 1.5rem;

  input,
  select {
    width: 100%;
    margin: 0.5rem 0;
    padding: 0.5rem;
    font-size: 0.875rem;
    border: 1px solid #9c9c9c;
    border-radius: 0.25rem;

    :disabled {
      color: #777777;
      background: #eeeeee;
    }
  }
`;

const FormField = ({ title, name, value, type = 'text', required = false, options, onChange, disabled = false }: Props) => (
  <Box>
    <CardTitle title={title} required={required} />
    {options ? (
      <select name={name} value={value} onChange={onChange} disabled={disabled}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <input type={type} name={name} value={value ?? ''} onChange={onChange} disabled={disabled} />
    )}
  </Box>
);

export default FormField;
