import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';
import createRequestSaga, { createRequestActionTypes } from './lib/createRequestSaga';
import * as hotelAPI from '../api/hotel';
import { CommonProps, configSuccessProps, GetPayload } from '../types/commons';
import { ConfigState } from '../types/config';

type Payloads = GetPayload<
  typeof initialize | typeof changeField | typeof changeResult | typeof selectConfigAction | typeof updateConfigAction
>;

const INITIALIZE = 'config/INITIALIZE';
const CHANGE_FIELD = 'config/CHANGE_FIELD';
const CHANGE_RESULT = 'config/CHANGE_RESULT';

const initialState: ConfigState = {
  config: {
    hotel_image: '',
    rooms_image: '',
    api_key: '',
    checkinable_not_assigned: 'FALSE',
    checkinable_not_paid: 'FALSE',
    id_prefix: '',
    is_ignore_paid: 'FALSE',
    is_issued_key: 'FALSE',
    link_code: '',
    link_pms_name: '',
    reservation_no_postfix: '',
    reservation_no_prefix: '',
    timeout: 0,
    sa_api_key: '',
    checkin_enable_time: '',
    customer_service_number: '',
    latitude: 0,
    longitude: 0,
    radius: 0,
  },
  configSelectError: null,
  configUpdateSuccess: false,
  configUpdateError: null,
};

const [CONFIG_SELECT, CONFIG_SELECT_SUCCESS, CONFIG_SELECT_FAILURE] = createRequestActionTypes('config/CONFIG_SELECT');
const [CONFIG_UPDATE, CONFIG_UPDATE_SUCCESS, CONFIG_UPDATE_FAILURE] = createRequestActionTypes('config/CONFIG_UPDATE');

export const initialize = createAction(INITIALIZE);
export const changeField = createAction(CHANGE_FIELD, ({ key, value }: CommonProps) => ({
  key,
  value,
}));
export const changeResult = createAction(CHANGE_RESULT, ({ key, value }: CommonProps) => ({
  key,
  value,
}));
export const selectConfigAction = createAction(CONFIG_SELECT);
export const updateConfigAction = createAction(CONFIG_UPDATE, (formData: FormData) => formData);

const selectConfigSaga = createRequestSaga(CONFIG_SELECT, hotelAPI.hotelConfig);
const updateConfigSaga = createRequestSaga(CONFIG_UPDATE, hotelAPI.updateHotelConfig);

export function* configSaga() {
  yield takeLatest(CONFIG_UPDATE, updateConfigSaga);
  yield takeLatest(CONFIG_SELECT, selectConfigSaga);
}

export const config = handleActions<ConfigState, Payloads>(
  {
    [INITIALIZE]: () => initialState,
    [CHANGE_FIELD]: <K extends keyof ConfigState['config']>(
      state: ConfigState,
      { payload: { key, value } }: { payload: { key: K; value: ConfigState['config'][K] } }
    ) =>
      produce(state, (draft) => {
        draft.config[key] = value;
      }),
    [CHANGE_RESULT]: (state, { payload: { key, value } }: configSuccessProps) =>
      produce(state, (draft) => {
        draft[key] = value;
      }),
    [CONFIG_UPDATE_SUCCESS]: (state) => ({
      ...state,
      configUpdateSuccess: true,
      configUpdateError: null,
    }),
    [CONFIG_UPDATE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      configUpdateSuccess: false,
      configUpdateError: error,
    }),
    [CONFIG_SELECT_SUCCESS]: (
      state,
      { payload: { api_key, hotel_image, sa_api_key, rooms_image, pms_config, smart_check_in_config } }
    ) => ({
      ...state,
      config: {
        api_key,
        hotel_image,
        sa_api_key,
        rooms_image,
        ...pms_config,
        ...smart_check_in_config,
      },
      configSelectError: null,
    }),
    [CONFIG_SELECT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      configSelectError: error,
    }),
  },
  initialState
);
